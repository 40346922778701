import { Redirect } from 'react-router-dom';

import { appsPath as appsPathDefault, basePath } from '/constants';
import { Loadable } from '/common/Loadable';
import { GoogleLoginRedirectHandler } from '/component/Login';
import { AppPage } from '/common/AppPage';

import { IRouteProps } from './models/IRouteProps';
import * as Lazy from './lazyRoute';
import { ANALYTICS_PATH } from './routeConstants';

function url(base: string, routeUrl: string): string {
  return (base === '/' ? '' : base) + routeUrl;
}

const tgRoutes = (baseUrl: string): IRouteProps[] => {
  const filesPath = url(baseUrl, '/files');
  const playgroundPath = url(baseUrl, '/playground');
  const appsPath = url(baseUrl, appsPathDefault);

  const appPath = url(appsPath, '/manage');
  const devicePath = url(basePath, '/device');
  const configPath = url(basePath, '/configuration');
  const economyPath = url(basePath, '/economy');
  const socialPath = url(basePath, '/social');
  const analyticsPath = url(basePath, ANALYTICS_PATH);
  const liveopsPath = url(basePath, '/liveops');
  const uaPath = url(basePath, '/ua');
  const supportPath = url(basePath, '/support');
  const corpPath = url(baseUrl, '/corp');
  const mlPath = url(basePath, '/ml');
  const userPath = url(baseUrl, '/user-profile');
  const status = url(baseUrl, '/status');
  const servicesPath = url(basePath, '/services');
  const keysManagerPath = url(baseUrl, '/keys-manager');
  const billingPath = url(baseUrl, '/billing');

  const playgroundConfig = {
    path: playgroundPath,
    component: (props = {}) => <Loadable>{<Lazy.Playground basePath={playgroundPath} {...props} />}</Loadable>,
  };

  const config: IRouteProps = {
    path: baseUrl,
    component: AppPage,
    routes: [
      {
        path: baseUrl,
        exact: true,
        component: () => <Redirect to={appsPath} />,
      },
      {
        path: filesPath,
        component: (props) => <Loadable>{<Lazy.Files basePath={filesPath} {...props} />}</Loadable>,
      },
      {
        path: devicePath,
        component: (props) => <Loadable>{<Lazy.Device basePath={devicePath} {...props} />}</Loadable>,
      },
      {
        path: configPath,
        component: (props) => <Loadable>{<Lazy.Configuration basePath={configPath} {...props} />}</Loadable>,
      },
      {
        path: economyPath,
        component: (props) => <Loadable>{<Lazy.Economy basePath={economyPath} {...props} />}</Loadable>,
      },
      {
        path: socialPath,
        component: (props) => <Loadable>{<Lazy.Social basePath={socialPath} {...props} />}</Loadable>,
      },
      {
        path: analyticsPath,
        component: (props) => <Loadable>{<Lazy.Analytics basePath={analyticsPath} {...props} />}</Loadable>,
      },
      {
        path: liveopsPath,
        component: (props) => <Loadable>{<Lazy.LiveOps basePath={liveopsPath} {...props} />}</Loadable>,
      },
      {
        path: uaPath,
        component: (props) => <Loadable>{<Lazy.UA basePath={uaPath} {...props} />}</Loadable>,
      },
      {
        path: supportPath,
        component: (props) => <Loadable>{<Lazy.Support basePath={supportPath} {...props} />}</Loadable>,
      },
      {
        path: mlPath,
        component: (props) => <Loadable>{<Lazy.MLRoute basePath={mlPath} {...props} />}</Loadable>,
      },
      {
        path: servicesPath,
        component: (props) => <Loadable>{<Lazy.Services basePath={servicesPath} {...props} />}</Loadable>,
      },
      {
        path: corpPath,
        component: (props) => <Loadable>{<Lazy.Corp basePath={corpPath} {...props} />}</Loadable>,
      },
      {
        path: userPath,
        component: (props) => <Loadable>{<Lazy.UserProfileRoute basePath={userPath} {...props} />}</Loadable>,
      },
      {
        path: status,
        component: (props) => <Loadable>{<Lazy.UserStatus basePath={status} {...props} />}</Loadable>,
      },
      {
        path: appsPath,
        component: (props) => <Loadable>{<Lazy.Apps basePath={appsPath} baseAppPath={appPath} {...props} />}</Loadable>,
      },
      {
        path: keysManagerPath,
        component: (props) => <Loadable>{<Lazy.KeysManager basePath={keysManagerPath} {...props} />}</Loadable>,
      },
      {
        path: billingPath,
        component: (props) => <Loadable>{<Lazy.BillingRoute basePath={playgroundPath} {...props} />}</Loadable>,
      },
    ],
  };

  if (process.env.NODE_ENV !== 'production') {
    config.routes?.push(playgroundConfig);
  }

  return [config];
};

export const routesConfig: IRouteProps[] = [
  /*
  {
    path: '/landing',
    component: () => <>Landing page</>,
    secure: false,
  },
  */
  /*
 {
   path: '/services',
   secure: false,
   component: () => <>Services page</>,
  },
  */
  {
    path: '/login',
    inverse: true,
    component: (props) => <Loadable>{<Lazy.LoginRoute {...props} />}</Loadable>,
  },
  {
    path: '/resetPassword',
    inverse: true,
    component: (props) => <Loadable>{<Lazy.ResetPasswordRoute {...props} />}</Loadable>,
  },
  {
    path: '/auth/google',
    component: GoogleLoginRedirectHandler,
    secure: false,
  },
  /*
  {
    path: '/register',
    inverse: true,
    component: () => <>Register Page</>,
  },
 */
  ...tgRoutes('/'),
];
